import updateCoverElement from "./updateCoverElement";

export default (node: HTMLElement) => {
    const coverEl = document.createElement('div');
    coverEl.classList.add('t-coverElement')

    updateCoverElement(node, coverEl, false);

    coverEl.addEventListener('click', (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation()
    })
    node.appendChild(coverEl);
    return coverEl;
}

