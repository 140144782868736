import { App, ref, watch } from 'vue'
import makeCoverElement  from './editable-utils/makeCoverElement'
import updateCoverElement from './editable-utils/updateCoverElement'
import registerStyle from './editable-utils/registerStyle'
import getCMEl from './editable-utils/getCMEl'
import getEditParent from './editable-utils/getEditParent'
import Sortable from 'sortablejs';
import handleMoveNode from '~/utils/handleMoveNode'

/* 
    add v-edit-parent on parent, and add v-edit-child to child element that
    contains displayed text
*/


export const editMode = ref(false)

if(process.client){
    editMode.value = localStorage.getItem('__tuding_editable') ? 
                    localStorage.getItem('__tuding_editable') == 'true'
                    :false
}

watch(()=>editMode.value,()=>{
    if(process.client){
        localStorage.setItem('__tuding_editable', String(editMode.value))
    }
})

const toggleCMOptions = ( el:HTMLElement, options:string[]) => {
    const opEls = Array.from(el.querySelectorAll('.t-editable-contextMenuItem')) as HTMLElement[]
    for(let opEl of opEls) {
        if(options.includes(opEl.innerText)){
            opEl.style.display="block"
        }else{
            opEl.style.display="none"
        }
    }

}

const plugin = {
    install: (app:App) => {

        // edit-mode provider
        app.provide("editMode", editMode)


        // context menu
        let contextmenuEl = undefined as HTMLElement | undefined
        let targetElData = ref({route:'', display:'', cm_options:[]})
        const getTargetElData = () => targetElData.value
        let cmDispatchEl = ref(undefined as HTMLElement | undefined)
        const dispatchCMClick = (targetData:any) => (ev:Event) => {
            if(!ev.target) return
            // if(!targetEl.value) return
            if(!cmDispatchEl.value) return
            const dataset = (ev.target as HTMLElement).dataset
            const cmClickEvent = new CustomEvent('t-cm-click', 
                    {detail:{
                        key:dataset.value, 
                        data:targetData
                    }}
                )
            cmDispatchEl.value.dispatchEvent(cmClickEvent)
        }

        // edit-child
        const updateEditChild = (el:any, coverEl:any) => {
            if(!!editMode.value){
                el.getEventListeners
                el.draggable = true
                updateCoverElement(el, coverEl, true)
            }else{
                el.draggable = false
                updateCoverElement(el, coverEl, false)
            }
        }
        app.directive('edit-child', {
            mounted: (el, binding) => {
                // react to edit mode change
                watch( ()=>editMode.value, 
                    () => updateEditChild(el, coverEl)
                )
                // prepare edit-child style for cover element
                el.style.boxSizing = 'border-box'
                el.classList.add('--editable-edit-child')
                el.setAttribute('data-teditable',true)

                // create & mount cover element
                const coverEl = makeCoverElement(el)
                coverEl.style.display="none"
                if(el.dataset.z_index){
                    coverEl.style.zIndex = el.dataset.z_index
                }
                updateEditChild(el, coverEl)
                
                // initialize context menu on first edit-child mounted
                if(!contextmenuEl){
                    contextmenuEl = getCMEl()
                    contextmenuEl.addEventListener('click', e=>{
                        dispatchCMClick(getTargetElData())(e)
                    })
                    registerStyle()
                }

                // context menu events
                coverEl.addEventListener("contextmenu", e=>{
                    e.preventDefault()
                    
                    targetElData.value = el.dataset
                    if(!contextmenuEl) return
                    
                    toggleCMOptions(contextmenuEl, targetElData.value.cm_options)

                    const {bottom, left, right, top, width} = coverEl.getBoundingClientRect()
                    const cmBox = document.querySelector('#t-editableContextMenu')!
                                            .getBoundingClientRect()
                    contextmenuEl.style.top = `${bottom}px`;
                    if(width > cmBox.width){
                        contextmenuEl.style.left = `${right - cmBox.width}px`;
                    }else{
                        contextmenuEl.style.left = `${left}px`;
                    }
                    
                    cmDispatchEl.value = el
                })
                document.querySelector('body')!
                .addEventListener("click", e=>{
                    if(!contextmenuEl) return
                    if(!e.target) return
                    //@ts-ignore
                    if (e.target.offsetParent != contextmenuEl) {
                        contextmenuEl.style.top = '200vh'
                        contextmenuEl.style.left = '200vw'
                    }
                })

                // handle drag & drop
                const updateChildren = () => {

                }
                el.addEventListener("dragstart", (e:DragEvent)=>{
                    if(e.target!= el){
                        e.stopPropagation()
                    }
                    
                    el.classList.add("t-dragging")
                    const parentEl = getEditParent(el)
                    if(!parentEl) return
                    // parentEl.style.border = "1px solid red"

                })
                el.addEventListener("dragend", (e:DragEvent)=>{
                    if(e.target!= el){
                        e.stopPropagation()
                    }
                    el.classList.remove("t-dragging")
                    const parentEl = getEditParent(el)
                    if(!parentEl) return
                    // parentEl.style.border = "none"
                })
            }
        })

        // edit parent
        app.directive('edit-parent', {
            mounted: (el, binding) => {
                
                // prepare styles
                el.style.boxSizing = 'border-box'
                el.droppable = true
                el.classList.add('--editable-edit-parent')

                // children id
                const children = Array.from(el.children as HTMLElement[] || [])
                if(!children) return
                for(let i = 0; i < children.length; i++){
                    children[i].dataset.id = String(i + 1)
                }

                // handle drag & drop
                const sortable = Sortable.create(el)
                const initialOrder = sortable.toArray()
                sortable.option("disabled", true)
                sortable.option("onEnd", e =>{
                    if(!e.newIndex) return
                    handleMoveNode(e.item.dataset as any, e.newIndex, sortable, initialOrder)
                })

                el.addEventListener("dragstart", (e:DragEvent)=>{
                    e.stopPropagation()
                })
                
                // react to edit mode change
                watch( ()=>editMode.value, 
                () =>{
                        sortable.option("disabled", !editMode.value)
                    }
                )

            }
        })
    }
}

export default defineNuxtPlugin(nuxtApp => {
    // Doing something with nuxtApp
    nuxtApp.vueApp.use(plugin)
})
  