export default () => {
    const contextMenuHTML = `
    
    <div class="t-editable-contextMenu" id="t-editableContextMenu">
        <div class="t-editable-contextMenu-inner" id="t-editableContextMenuInner">
            <div data-value="edit" class="t-editable-contextMenuItem">重命名</div>
            <div data-value="add_before" class="t-editable-contextMenuItem">在前添加</div>
            <div data-value="add_after" class="t-editable-contextMenuItem">在后添加</div>
            <div data-value="add_after" class="t-editable-contextMenuItem">添加目录</div>
            <div data-value="add_under" class="t-editable-contextMenuItem">添加次级</div>
            <div data-value="change_icon" class="t-editable-contextMenuItem">修改图标</div>
            <div data-value="delete" class="t-editable-contextMenuItem">删除</div>
        </div>
    </div>
            `

    const body = document.querySelector('#__nuxt') || document
    const cmEl = document.createElement('div')
    cmEl.innerHTML = contextMenuHTML
    cmEl.style.position = 'fixed'
    cmEl.style.top = '200vh'
    cmEl.style.left = '200vw'
    cmEl.style.zIndex = '1000'
    cmEl.style.width = 'fit-content'
    // body.appendChild(cmEl)
    return cmEl
}
