export default () => {
    const style = document.createElement('style');
    style.type = 'text/css'
    style.innerHTML = `
        .t-coverElement { 
            background-color: #e6605c22 !important;
            transition: all 0.2s linear;
            cursor:pointer;
        }
        .t-coverElement:hover { 
            border:1px solid red;
            background-color: #e6605c22 !important
        }
        .t-coverElement.changed {
            border:1px solid red;
        }
        .t-adtiveParentElem { 
            box-sizing:border-box;
        }
        .t-adtiveParentElem:hover { 
            border:1px solid black;
        }

        .t-editable-contextMenu{
            position:fixed;
            z-index:10;
            border:1px solid #eee;
            background-color:white;
            box-shadow:0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
        }

        .t-editableContextMenuInner{
            position:absolute;
            z-index:1000
        }
        .t-editable-contextMenuItem{
            cursor:pointer;
            background-color:white;
            z-index:10;
            padding:3px 10px;
            font-weight:300;
            transition: all 0.2s linear;
        }
        .t-editable-contextMenuItem:hover{
            background-color:#eee
        }

        .t-dragging{
            opacity:0.3;
        }

    `;
    document.getElementsByTagName('head')[0].appendChild(style);
}
