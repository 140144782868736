export default (el:HTMLElement) => {
    const guard = 1000
    let doneLayers = 0
    let pointer = el
    while(true){
        if(doneLayers > guard) break

        const p = pointer.parentNode as HTMLElement | null
        
        if(!p) return
        if(p.classList.contains('--editable-edit-parent')) return p
        
        pointer = p
        doneLayers += 1
    }
}