import axios from "axios";
import { pinyinSort } from "~/utils/pinyinSort";
import {getSignedFilePaths} from "~/utils/getSignedFilePaths"

const isWithKnownFileType = (file: DingFileDriveFile): boolean => {
    if(file.type === "FOLDER"){
        return true
    }
    const ext = getFileExtension(file)
    return [".jpg", ".jpeg", ".png", ".pdf", ".xlsx", ".json", ".txt"].includes(ext)
}

export const getMultifilePageContentType = (file: DingFileDriveFile): ".images" | ".gallery" => {
    
    let multiFileExt = ""
    if(file.type === "FOLDER"){
        const extCounts = file.children.reduce((acc, child)=>{
            const ext = getFileExtension(child)
            if(acc[ext]){
                acc[ext].push(child)
            }else{
                acc[ext] = [child]
            }
            return acc
        }, {} as {[key: string]:DingFileDriveFile[]})

        multiFileExt = Object.keys(extCounts).reduce((a, b) => extCounts[a].length > extCounts[b].length ? a : b, Object.keys(extCounts)[0])
        // file.children = extCounts[multiFileExt]

    }else{
        multiFileExt = getFileExtension(file)
    }
    switch(multiFileExt){
        case ".jpg":
        case ".jpeg":
        case ".png":
            return ".images"
        case ".pdf":
        case ".xlsx":
        case ".json":
            return ".gallery"
    }
}

function getFileExtension(file: DingFileDriveFile): string {
    const parts = file.name.split(".");
    if (parts.length < 2) {
      return ""; // No extension or invalid input
    }
    return `.${parts[parts.length - 1]}`.toLowerCase();
}


const getFileListFromTxtURL = async (txtFileURL: string) => {
    console.log("getFileListFromTxtURL", txtFileURL)
    const textFileDataRes  = await axios.get(txtFileURL)
    if(textFileDataRes.status !== 200){
        return []
    }
    const textFileData = textFileDataRes.data

    console.log("textFileData", textFileData)
    const pathsToSign = textFileData.split("\n").filter((line)=>line.trim().length > 0)

    const signedFilePaths = await getSignedFilePaths(pathsToSign.filter((path)=>path.trim().length > 0))

    return pathsToSign.map((path:string)=>{
        return {
            name: path.split("/").pop() || "",
            display: path.split("/").pop() || "",
            file_url: "https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/" 
                        + (signedFilePaths[path]||"")
                                // .replace(/%/g,"%25")
                                // .replace(/\+/g,"%2B")
        }
    })
}

const compareImages = (a:DingFileDriveFile, b:DingFileDriveFile) => {
    const aNumberMatch = a.name.match(/_(\d+)/g)
    const bNumberMatch = b.name.match(/_(\d+)/g)
    if(aNumberMatch && bNumberMatch){
        const aNumber = parseInt(aNumberMatch[0].substring(1))
        const bNumber = parseInt(bNumberMatch[0].substring(1))
        return aNumber - bNumber
    }else if(aNumberMatch){
        return -1
    }else if(bNumberMatch){
        return 1
    }else{
        return pinyinSort(a.name, b.name)
    }
}

export const getPageContent = async (
    f: DingFileDriveFile
): Promise<{data:any, type:string}> => {

    console.log("getPageContent", f)

    if(!f){
        return {
            type: "nodata",
            data: []
        }
    }

    if(
        //@ts-ignore
        f.type == "FILE" ||
        (f.children.length == 1 && f.children[0].type == "FILE")
    ){


        const singleFile = (f.type == "FILE") ? f : f.children[0]


        // CASE: file rather than folder
        const signedChildrenFiles = await getSignedFiles([singleFile])
        const ext = getFileExtension(singleFile)
        console.log("getPageContent case 1", singleFile, singleFile.name.includes("【文件列表】"),  ext, signedChildrenFiles)
        switch(ext){
            case ".jpg":
            case ".jpeg":
            case ".png":
                return {type:"images", data: signedChildrenFiles.sort(compareImages)}
            case ".pdf":
                return {type:"pdf", data: signedChildrenFiles.slice(0,1)}
            case ".xlsx":
            case ".json":
                try{
                    const res = await axios.get(signedChildrenFiles[0].file_url)
                    return {type:"table", data: res.data}
                }catch(e){
                    console.error("Failed to parse json file", e)
                    return {type:"json", data: []}
                }
            case ".txt":
                if(singleFile.name.includes("【文件列表】")){
                    try{
                        const data = await getFileListFromTxtURL(signedChildrenFiles[0].file_url)
                        console.log("getFileListFromTxtURL res", data)

                        return {type:"gallery", data}
                    }catch(e){
                        console.error("Failed to parse json file", e)
                        return {type:"json", data: []}
                    }
                }else{

                }
        }
    }else{
        // CASE: f.children != 1

        f.children = f.children || []

        try{
            const signedChildrenFiles = await getSignedFiles(f.children)
            
            const isImages = getMultifilePageContentType(f)

            if(isImages === ".images"){
                return {type:"images", data: signedChildrenFiles}
            }else{
                return {type:"gallery", data: signedChildrenFiles}
            }
        }catch(e){
            console.error("Failed to get signed children files", e)
            return {type:"nodata", data: []}
        }
    }
    return {type:"nodata", data: []}
}

const getSignedFiles = async (files: DingFileDriveFile[]):Promise<DingFileDriveFile[]> => {

    const outFiles = [] as DingFileDriveFile[]
    const pathsToSign = [] as string[]

    for (let file of files) {
        if(!isWithKnownFileType(file)){
            continue
        }
        if (file.type === "FOLDER") {
            const fileType = getMultifilePageContentType(file)

            if(fileType === ".images"){
                for (let child of (file.children||[])) {
                    pathsToSign.push(child.path.replace(/\.xlsx$/g, ".json"))
                }
                file.group = ""
                file.groupName = ""
                file.displayType = "images"
                outFiles.push(file)
            }else{
                for (let child of (file.children||[])) {
                    
                    if(!isWithKnownFileType(child)){
                        continue
                    }
                    const childType = getMultifilePageContentType(child)

                    pathsToSign.push(child.path.replace(/\.xlsx$/g, ".json"))
                    child.group = file.display
                    child.groupName = file.name
                    outFiles.push(child)

                    if(childType === ".images"){
                        child.displayType = "images"
                        for (let subChild of (child.children||[])) {
                            if(!isWithKnownFileType(subChild)){
                                continue
                            }
                            pathsToSign.push(subChild.path.replace(/\.xlsx$/g, ".json"))
                        }
                    }
                }
            }
        }else{
            pathsToSign.push(file.path.replace(/\.xlsx$/g, ".json"))
            outFiles.push(file)
        }
    }

    const signedFilePaths = await getSignedFilePaths(pathsToSign.map((path)=>path.replace(/\.xlsx$/g, ".json")))

    for(let file of outFiles){
        file.file_url = "https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/" 
                        + (signedFilePaths[file.path.replace(/\.xlsx$/g, ".json")]||"")
                                // .replace(/%/g,"%25")
                                // .replace(/\+/g,"%2B")
        if(file.children){
            for(let child of (file.children||[])){
                child.file_url = "https://hm-cloud-v3.oss-cn-shenzhen.aliyuncs.com/" 
                                + (signedFilePaths[child.path.replace(/\.xlsx$/g, ".json")]||"")
                                    // .replace(/%/g,"%25")
                                    // .replace(/\+/g,"%2B")
            }
        }
    }
    return outFiles
}