export default (node: HTMLElement, coverEl:HTMLElement | undefined, show:boolean) => {
    if(!coverEl) return
    if(!show){
        coverEl.style.display = 'none';
    }else{
        coverEl.style.display = 'flex';
    }
    const box = node.getBoundingClientRect();
    coverEl.style.width = box.width + 'px';
    coverEl.style.height = box.height + 'px';
    coverEl.style.boxSizing = 'border-box';
    coverEl.style.border = node.style.border;
    coverEl.style.backgroundColor = 'transparent'

    switch (node.style.position){
        case 'absolute':
        case 'fixed':
            coverEl.style.position = node.style.position;
            coverEl.style.top = node.style.top;
            coverEl.style.left = node.style.left;
            break
        default:
            node.style.position = 'relative';
            coverEl.style.position = 'absolute';
            coverEl.style.top = '0px';
            coverEl.style.left = '0px';
    }
    return coverEl;
}