import {modal} from '@tian-libs/modal'

export default (
    dataset:{display:string, route:string}, 
    newIndex:number,
    sortable:any,
    initialOrder:any,
    jwt:string
)=>{
    modal.query(
        `确定移动节点 ${dataset.display} 吗`,
        async ()=>{
            const res = await tFetch('/api/site-data/edit/move', {
                jwt,
                method: 'POST',
                body:{
                    targetRoute:dataset.route,
                    newIndex
                }
            })
            modal.alert(`移动节点 ${dataset.display} 成功！`, ()=>{
                window.location.reload()
            })
        },
        ()=>{
            sortable.sort(initialOrder)
        }
    )
}